const carta_alimentos = [
  {
    categoria: 'Entradas',
    nombre: 'Papa al horno',
    descripcion: 'Papa bañada en nuestra tradicional salsa Alfredo.',
    precio: 99,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Salchicha a la parrilla',
    descripcion: 'Salchicha Frankfort en rebanadas acompañada de papa al horno.',
    precio: 149,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Pan Tomate Jamón Serrano',
    descripcion: 'Rebanadas de pan con un toque de pomodoro, jamón serrano y queso mozzarella.',
    precio: 158,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Tapas Bambinos',
    descripcion: 'Rebanadas de pan con tomate fresco, queso fundido y aceitunas negras.',
    precio: 149,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Crostino',
    descripcion: 'Rebanadas de pan con mozzarella fresco, jamón serrano, mantequilla a la Salvia y un toque de reducción balsámico.',
    precio: 169,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Base Trattoria',
    descripcion: '(Para 4 personas) Variedad de tapas, salchicha a la parrilla, papa al horno y vegetales frescos.',
    precio: 289,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Botana de jamón serrano',
    descripcion: '',
    precio: 232,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Panela al horno',
    descripcion: 'Deliciosa panela bañada en finas hierbas, ajo y un toque de hojuelas de chile.',
    precio: 189,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  /*{
    categoria: 'Entradas',
    nombre: 'Carpaccio atún',
    descripcion: '',
    precio: 217,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  /* {
    categoria: 'Entradas',
    nombre: 'Carpaccio de atún negro',
    descripcion: '',
    precio: 200,
    tiempo_preparacion: null,
    mas_vendido: false,
  }, */
  /*{
    categoria: 'Entradas',
    nombre: 'Carpaccio salmón',
    descripcion: '',
    precio: 243,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },*/
  {
    categoria: 'Entradas',
    nombre: 'Sashimi',
    descripcion: 'Sashimi de salmón o atún en salsas negras, acompañado de pepino y aguacate.',
    precio: 257,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Camarones cucaracha',
    descripcion: 'Elige entre nuestras salsas picantes o agridulces acompañados con ensalada.',
    precio: 219,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Papitas',
    descripcion: '',
    precio: 89,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Elote',
    descripcion: '',
    precio: 110,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Camarones en bolsa',
    descripcion: '(Elige entre regular, hot o extra hot igual no podrás dejar de chuparte los dedos) 1kg $740 1/2kg $373 1/4 $249',
    precio: null,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Cremas',
    descripcion: '(Champiñón, poblana, elote, pimiento o espárragos).',
    precio: 122,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Papa Armada',
    descripcion: '*Salsa a elegir: Alfredo, tres quesos o champiñones.* Proteína a elegir: pollo, molida, salchicha, tocino o chorizo.',
    precio: 179,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Papa Gratinada',
    descripcion: '*Salsa a elegir: Alfredo, tres quesos o champiñones.* Proteína a elegir: pollo, molida, salchicha, tocino o chorizo.',
    precio: 199,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Taco arrachera',
    descripcion: '2 tacos de tortilla de harina, arrachera en cubos, aguacate machacado, salsa de piña / habanero tatemada y chilitos güeros encurtidos.',
    precio: 134,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Taco gobernador',
    descripcion: '3 quesadillas rellenas de camarón, cebolla, chile poblano.',
    precio: 189,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Taco camarón',
    descripcion: '2 tacos de tortilla de harina, camarón, aguacate machacado, salsa de piña / habanero tatemada y chilitos güeros encurtidos.',
    precio: 134,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Taco mar y tierra',
    descripcion: '2 tacos de tortilla de harina, arrachera en cubos, camarones, guacamole y cebolla morada encurtida con chilitos güeros.',
    precio: 225,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Entradas',
    nombre: 'Quesadilla de arrachera',
    descripcion: 'Empalmada de tortilla de harina con costra de combinación de quesos, arrachera en cubos, guacamole acompañada de cebollas cambray y chiles verdes toreados.',
    precio: 159,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  /*************** Ensaladas */
  {
    categoria: 'Ensaladas',
    nombre: 'Espárragos Roll',
    descripcion: 'Lechuga, espárragos, tocino, parmesano y reducción balsámica.',
    precio: 127,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Ensaladas',
    nombre: 'Oriental',
    descripcion: 'Lechuga, suprema de toronja, mandarina, cacahuate, pepino y vinagreta de cacahuate.',
    precio: 127,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Ensaladas',
    nombre: 'Cesar',
    descripcion: 'Lechuga, Crotones, parmesano, pollo y aderezo cesar.',
    precio: 149,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Ensaladas',
    nombre: 'Cesar Premium',
    descripcion: 'Lechuga, jitomate, cebolla, pepino, crotones, queso parmesano, pollo, aderezo cesar y mostaza dulce.',
    precio: 189,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Ensaladas',
    nombre: 'Creta',
    descripcion: 'Lechuga, pimiento, cebolla morada, supremas de mandarina, jitomate cherry, pollo al coco con vinagreta coco-mandarín',
    precio: 189,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Ensaladas',
    nombre: 'Capresse',
    descripcion: 'Queso mozzarella fresco, tomate, aceituna negra, alcaparra y vinagreta balsámica.',
    precio: 127,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Ensaladas',
    nombre: 'Náutica',
    descripcion: 'Lechuga, pera, espárragos, camarones, queso y vinagreta de limón dulce.',
    precio: 199,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Ensaladas',
    nombre: 'Old navy',
    descripcion: 'Lechuga, manzana verde, apio, nuez y aderezo de yogurth.',
    precio: 137,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Ensaladas',
    nombre: 'Del Bosque',
    descripcion: 'Lechuga, manzana, supremas mandarina, arándano, nuez, queso de cabra y reducción de vino tinto.',
    precio: 169,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Ensaladas',
    nombre: 'Del rancho',
    descripcion: 'Lechuga, aguacate, pepino, granos de elote, arrachera y crotones con aderezo de tu preferencia.',
    precio: 199,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Ensaladas',
    nombre: 'Frutal',
    descripcion: 'Lechuga, durazno, manzana, uva, pera, piña, arándano y supremas de naranja con aderezo de tu preferencia.',
    precio: 157,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  /*** Pastas */
  {
    categoria: 'Pastas',
    nombre: 'A elegir Fetuccini / Espagueti',
    descripcion: 'Con pollo, camarón, arrachera, salmón o atún.',
    precio: 110,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Pomodoro',
    descripcion: 'Salsa Rústica de tomate con un ligero toque de albahaca.',
    precio: 99,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Arrabiata',
    descripcion: 'Salsa Pomodoro con un ligero sabor picante.',
    precio: 110,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Puttanesca',
    descripcion: 'Salsa Pomodoro marinada con alcaparra, aceituna, anchoa y un toque de vino blanco.',
    precio: 122,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Champiñones',
    descripcion: 'Salsa Pomodoro con champiñones y un ligero sabor cremoso',
    precio: 129,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Alfredo',
    descripcion: 'Salsa cremosa con una exquisita combinación de quesos.',
    precio: 149,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Tres quesos',
    descripcion: 'Salsa cremosa con una exquisita combinación de quesos.',
    precio: 149,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Al Pesto',
    descripcion: 'Salsa de albahaca, piñones, aceite de olivo y un toque parmesano.',
    precio: 152,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Boloñesa',
    descripcion: 'Tradicional pasta con carne molida y salsa pomodoro.',
    precio: 224,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Griega',
    descripcion: 'Trozos de salmón, alcaparra, aceitunas, alcachofas, palmito, elotito, pepinillo, salsa Pomodoro y un ligero toque de vino blanco.',
    precio: 237,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Rosa',
    descripcion: 'Salsa cremosa de tomate con un toque de vodka.',
    precio: 142,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Salmón',
    descripcion: 'Salsa cremosa con trozos de salmón al vino.',
    precio: 242,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Capresse',
    descripcion: 'Jitomate cherry, mozzarella y albahaca fresca.',
    precio: 165,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Tuna',
    descripcion: 'Atún fresco salteado con ajo, perejil, tomate cherry y un toque de vino blanco.',
    precio: 247,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pastas',
    nombre: 'Salmón veggie',
    descripcion: 'Pasta con variedad de vegetales en una salsa cremosa coronada con salmón al vino blanco.',
    precio: 257,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },

  /**** Platos fuertes */
  {
    categoria: 'Platos fuertes',
    nombre: 'Lasaña',
    descripcion: 'Tradicional y enorme rellena de carne y trocitos de vegetales.',
    precio: 289,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Lasaña de mariscos',
    descripcion: 'Cremosa e irresistible rellena de salmón, atún, camarón y surimi.',
    precio: 337,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Atún en salsa de durazno',
    descripcion: 'Medallón de atún, sobre un espejo de cremosa salsa de durazno al tequila. Acompañado de un bouquet de espárragos con jamón serrano y ensalada capri',
    precio: 289,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Atún Sellado',
    descripcion: 'En costra de pimienta acompañado de puré de papa y vegetales salteados.',
    precio: 269,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Salmón al horno',
    descripcion: 'Filete de salmón horneado, acompañado de láminas de papa, champiñón y pimiento a la plancha con un toque de reducción balsámico.',
    precio: 299,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Salmón al romero',
    descripcion: 'Acompañado de espárragos y papas.',
    precio: 299,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Salmón dulce',
    descripcion: 'Deliciosa loncha acompañada de puré de papa y vegetales salteados.',
    precio: 299,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Salmón al limón',
    descripcion: 'Loncha en salsa de limón y alcaparra a la mantequilla.',
    precio: 299,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Pollo al limón',
    descripcion: 'Suprema de pollo en salsa al limón y alcaparras a la mantequilla.',
    precio: 267,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Sandwich de salmón',
    descripcion: 'Relleno de loncha de salmón, lechuga, jitomate, acompañada de láminas de papa frita.',
    precio: 279,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Sandwich York',
    descripcion: 'Relleno de basto jamón York, lechuga, jitomate, cebolla morada, acompañado de láminas de papa frita.',
    precio: 199,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Suprema de pollo a la parmesana',
    descripcion: 'Pechuga de pollo empanizado, con un toque de pomodoro gratinado.',
    precio: 289,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Pollo fungi',
    descripcion: 'Pechuga con champiñones en salsa cremosa acompañada con puré de papa y vegetales salteados.',
    precio: 287,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Pollo al coco',
    descripcion: 'Con costra de coco horneado papa al horno y ensalada.',
    precio: 289,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Camarones al coco',
    descripcion: 'Cubiertos de coco horneado acompañados de papa al horno y ensalada.',
    precio: 289,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Camarones al vino',
    descripcion: 'Bañados en una deliciosa salsa de vino blanco y ajo acompañados de puré de papa y vegetales salteados.',
    precio: 279,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Chile del patrón',
    descripcion: 'Chile ancho relleno de arrachera en un espejo de frijol negro y crujientes de tortilla.',
    precio: 289,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Chile relleno de camarón',
    descripcion: 'Chile ancho relleno de camarones y vegetales sobre en un espejo de crema de azafrán.',
    precio: 289,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Arrachera',
    descripcion: '250gr de carne acompañados de papa al horno y ensalada.',
    precio: 289,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Arrachera campesina',
    descripcion: 'En tiras con una mezcla de espárragos y elote baby acompañados de pasta de salsa Alfredo y vegetales salteados.',
    precio: 274,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Arrachera rellena de camarón',
    descripcion: 'Acompañada de papa al horno y vegetales salteados.',
    precio: 387,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Molcajete mar y tierra',
    descripcion: '(Para 2 personas) Camarón, arrachera, pollo y chorizo acompañados de vegetales gratinados y quedadilla.',
    precio: 449,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Tabla mar y tierra',
    descripcion: '(Para 4 personas) Fajitas de arrachera, pollo, chorizo y camarones acompañados de vegetales con queso fundido, quesadillas, chiles toreados y cebollitas.',
    precio: 657,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Baguette',
    descripcion: 'Lechuga, jitomate, cebolla, chile y pepino +2 ingredientes, jamón, tocino, salami, chorizo, pollo, peperoni, jamón serrano o queso.',
    precio: 189,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Hamburguesas cuarto de kilo',
    descripcion: '250gr de deliciosa carne jugosa acompañada de papa en lámina.',
    precio: 189,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Hamburguesas de pollo',
    descripcion: 'Carne 100% pechuga de pollo, queso mozzarella, lechuga, rebanadas de tomate, cebolla y jalapeño',
    precio: 159,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Hamburguesa BBQ',
    descripcion: '250gr de arracherra picada, cebolla y champiñones caramelizados en salsa BBQ, bañada en queso fundido acompañados con papa en lámina.',
    precio: 199,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Hamburguesa Tender',
    descripcion: 'Tender de pollo bañado en salsa búfalo o bbq, con aros de cebolla y salsa ranch.',
    precio: 189,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Hamburguesa Mar y Tierra',
    descripcion: 'Arrachera, camarón, cebolla y pimiento caramelizados con queso fundido.',
    precio: 269,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Fajitas de arrachera',
    descripcion: '',
    precio: 249,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Platos fuertes',
    nombre: 'Fajitas de pollo',
    descripcion: '',
    precio: 239,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  /*{
    categoria: 'Platos fuertes',
    nombre: 'Pollo a la plancha',
    descripcion: null,
    precio: 199,
    tiempo_preparacion: 'De 20 a 25 minutos',
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  /***pizzas */
  {
    categoria: 'Pizzas',
    nombre: 'Margarita',
    descripcion: 'Jitomate, albahaca y queso,',
    precio: 176,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  /*{
    categoria: 'Pizzas',
    nombre: 'Margarita tradicional',
    descripcion: '(Jitomate, albahaca y mozzarella fresco) Vegetariana / Tres quesos / Hawaiana',
    precio: 199,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },*/
  {
    categoria: 'Pizzas',
    nombre: 'Vegetariana',
    descripcion: 'Jitomate, champiñón, pimiento, cebolla y aceituna',
    precio: 199,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Tres quesos',
    descripcion: '',
    precio: 199,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Hawaiana',
    descripcion: 'Jamón y piña',
    precio: 199,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Griega',
    descripcion: 'Queso de cabra, aceituna, jitomate y albahaca.',
    precio: 209,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Pegg',
    descripcion: 'Albahaca, jitomate, queso de cabra y champiñón.',
    precio: 209,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Frankfort',
    descripcion: 'Salchicha Frankfort, jitomate y albahaca.',
    precio: 249,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Carnes frías',
    descripcion: 'Jamón, salami, tocino.',
    precio: 249,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Le`liche',
    descripcion: 'Jitomate, champiñón, aceituna negra, piña, queso de cabra y albahaca.',
    precio: 237,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Mexicana',
    descripcion: 'Jamón, chorizo, cebolla y jalapeño',
    precio: 249,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Española',
    descripcion: 'Chorizo, tocino, pimiento.',
    precio: 249,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Parrillada (no ½)',
    descripcion: 'Pollo, cebolla, pimiento y champiñón.',
    precio: 249,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Fajita (no ½)',
    descripcion: 'Arrachera, cebolla, pimiento y champiñón.',
    precio: 259,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Deli',
    descripcion: 'Peperoni y champiñón.',
    precio: 259,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Pepperoni',
    descripcion: '',
    precio: 249,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Ámbar (no ½)',
    descripcion: 'Carne molida, piña y champiñones.',
    precio: 269,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Marinara (no ½)',
    descripcion: 'Camarón, anchoa, jitomates y albahaca.',
    precio: 279,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Mediterránea (no ½)',
    descripcion: 'Camarón y calabacitas salteadas.',
    precio: 279,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Bambinos',
    descripcion: 'Jamón serrano, pera, uva y queso azul.',
    precio: 279,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Trattoria',
    descripcion: 'Jamón serrano, higo y nuez.',
    precio: 279,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Maxxima',
    descripcion: 'Peperoni, salchicha italiana, jamón y tocino.',
    precio: 289,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Cataluña',
    descripcion: 'Queso y jamón serrano.',
    precio: 279,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Poblana ( no ½)',
    descripcion: 'Como base salsa de queso, pollo, chile poblano, cebolla y elote.',
    precio: 289,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Mar y tierra ( no ½)',
    descripcion: 'Arrachera y camarón.',
    precio: 299,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Norteña',
    descripcion: 'Arrachera, cebollitas cambray y chiles verde.',
    precio: 289,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'BBQ Chicken',
    descripcion: 'Pollo en salsa bbq cebollita caramelizada y doble queso.',
    precio: 289,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Gourmet',
    descripcion: 'Salmón, elotillo, aceituna negra y alcachofa.',
    precio: 299,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Napoli (no ½)',
    descripcion: 'Extra queso, carne molida, tocino, peperoni y jamón',
    precio: 299,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Frutti di mare (no ½)',
    descripcion: 'Salmón, atún, camarón y anchoa.',
    precio: 349,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Especial (no ½)',
    descripcion: 'Tomate deshidratados, camarón y jamón serrano.',
    precio: 349,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Deluxe (no ½)',
    descripcion: 'Peperoni, salami, tocino, carne molida, cebolla, pimiento, champiñón y aceituna negra.',
    precio: 339,
    tiempo_preparacion: null,
    mas_vendido: true,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Discada (no ½)',
    descripcion: 'Arrachera, pollo, tocino, salchicha, pimiento y cebolla.',
    precio: 379,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Ingredientes extra',
    descripcion: 'Jitomate, pimiento, champiñón, aceitunas, jalapeño, cebolla, piña. ',
    precio: 52,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Ingredientes extra',
    descripcion: 'Salami, jamón, salchicha frankfot, queso de cabra, chorizo, tocino, anchoa, Pepperoni.',
    precio: 98,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Ingredientes extra',
    descripcion: 'Camarón, arrachera, salmón, jamón serrano, pollo, carne molida, atún, espárrago, queso extra.',
    precio: 122,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Chimichurri',
    descripcion: '2 oz',
    precio: 28,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Chimichurri',
    descripcion: '6 oz',
    precio: 59,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },

  /*{
    categoria: 'Pizzas',
    nombre: 'Pacífico',
    descripcion: 'Atún de lata, cebolla y jalapeño',
    precio: 196,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },

  {
    categoria: 'Pizzas',
    nombre: 'Carmelita',
    descripcion: 'Esparragos, ajo y un toque de hojuelas de chile ',
    precio: 189,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Siciliana',
    descripcion: 'Champiñón, piña, anchoas y aceituna.',
    precio: 199,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Pastor',
    descripcion: 'Carne al pastor, piña, cebolla y cilantro.',
    precio: 209,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Supreme',
    descripcion: 'Peperoni, pimiento, champiñón, aceituna, piña.',
    precio: 209,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Pizzas',
    nombre: 'Texana',
    descripcion: 'Jamón, chorizo, salchicha italiana y tocino.',
    precio: 209,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },/*
  /****postres */
  {
    categoria: 'Postres',
    nombre: 'Pastel de la casa',
    descripcion: '',
    precio: 98,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  /*{
    categoria: 'Postres',
    nombre: 'Trio de cheese cake',
    descripcion: '',
    precio: 94,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },*/
  {
    categoria: 'Postres',
    nombre: 'Flan',
    descripcion: '',
    precio: 89,
    tiempo_preparacion: null,
    mas_vendido: false,
    tipo: 'carta_alimentos',
  },
  {
    categoria: 'Clericot',
    nombre: '½ jarra',
    descripcion: 'Clericot -blanco / tinto / rosado',
    precio: 229,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'clericot',
  },
  {
    categoria: 'Clericot',
    nombre: '1 jarra',
    descripcion: 'Clericot -blanco / tinto / rosado',
    precio: 299,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'clericot',
  },
  {
    categoria: 'Clericot',
    nombre: 'Copa de Clericot',
    descripcion: '',
    precio: 96,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'clericot',
  },
  {
    categoria: 'Clericot',
    nombre: 'Clerishot',
    descripcion: 'Clericot de tu preferencia con 4 oz de vodka',
    precio: 375,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'clericot',
  }
];

export { carta_alimentos };
