export const carta_bebidas = [
  {
    categoria: 'Refrescos',
    nombre: 'Coca Cola',
    descripcion: '',
    precio: 34,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Refrescos',
    nombre: 'Coca Cola Light',
    descripcion: '',
    precio: 34,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Refrescos',
    nombre: 'Coca cola sin azúcar',
    descripcion: '',
    precio: 34,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Refrescos',
    nombre: 'Manzanita',
    descripcion: '',
    precio: 34,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Refrescos',
    nombre: 'Fanta',
    descripcion: '',
    precio: 34,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Refrescos',
    nombre: 'Sprite',
    descripcion: '',
    precio: 34,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Refrescos',
    nombre: 'Agua mineral',
    descripcion: '',
    precio: 34,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Refrescos',
    nombre: 'Agua embotellada',
    descripcion: '',
    precio: 34,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Sin Alcohol',
    nombre: 'Rusa',
    descripcion: '',
    precio: 42,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Sin Alcohol',
    nombre: 'Limonada',
    descripcion: '',
    precio: 42,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Sin Alcohol',
    nombre: 'Naranjada',
    descripcion: '',
    precio: 42,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Sin Alcohol',
    nombre: 'Jugo de piña',
    descripcion: '',
    precio: 42,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Sin Alcohol',
    nombre: 'Jugo de naranja',
    descripcion: '',
    precio: 42,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Sin Alcohol',
    nombre: 'Jugo de arándano',
    descripcion: '',
    precio: 42,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Sin Alcohol',
    nombre: 'Jugo de durazno',
    descripcion: '',
    precio: 42,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Sin Alcohol',
    nombre: 'Piñada',
    descripcion: '',
    precio: 88,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Cerveza',
    nombre: 'Corona extra',
    descripcion: '',
    precio: 45,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Cerveza',
    nombre: 'Corona light',
    descripcion: '',
    precio: 45,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Cerveza',
    nombre: 'Victoria',
    descripcion: '',
    precio: 45,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Cerveza',
    nombre: 'Pacifico',
    descripcion: '',
    precio: 45,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Cerveza',
    nombre: 'Stella artois',
    descripcion: '',
    precio: 55,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Cerveza',
    nombre: 'Negra modelo',
    descripcion: '',
    precio: 55,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Cerveza',
    nombre: 'Modelo especial',
    descripcion: '',
    precio: 55,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Cerveza',
    nombre: 'Michelob ultra',
    descripcion: '',
    precio: 55,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Cerveza',
    nombre: 'Michelada',
    descripcion: '',
    precio: 59,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Cerveza',
    nombre: 'Clamato preparado',
    descripcion: '',
    precio: 47,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Cerveza',
    nombre: 'Cubeta 6 cervezas',
    descripcion: '',
    precio: 209,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Cerveza',
    nombre: 'Cubeta especial 6 cervezas',
    descripcion: '',
    precio: 275,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Jarras',
    nombre: 'Limonada',
    descripcion: '',
    precio: 87,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Jarras',
    nombre: 'Naranjada',
    descripcion: '',
    precio: 87,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Jarras',
    nombre: 'Jugo',
    descripcion: 'naranja, durazno, piña, arandano',
    precio: 99,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  /*{
    categoria: 'Jarras',
    nombre: 'Agua de Jamaica',
    descripcion: '',
    precio: 80,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },*/
  {
    categoria: 'Café',
    nombre: 'Americano',
    descripcion: '',
    precio: 39,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Café',
    nombre: 'Expresso',
    descripcion: '',
    precio: 36,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Café',
    nombre: 'Capuchino',
    descripcion: '',
    precio: 54,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Sangría dorada',
    descripcion: 'Vino tinto+manzana',
    precio: 89,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Sangría plata',
    descripcion: 'Vino tinto+limonada',
    precio: 89,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Piña colada',
    descripcion: 'Ron, jugo de piña, crema de coco',
    precio: 92,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Ruso negro',
    descripcion: 'Vodka + licor de café + dash evaporada',
    precio: 99,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Sex on the beach',
    descripcion: 'Vodka, jugo de naranja y arándano',
    precio: 98,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Gin tonic tanqueray',
    descripcion: '',
    precio: 123,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Gin tonic moms',
    descripcion: 'Ginebra moms y frutos rojos',
    precio: 132,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Gin tonic Hendricks',
    descripcion: 'Ginerbra Hendricks, pepino y romero',
    precio: 143,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Gin tonic Bombay',
    descripcion: 'Ginebra Bombay, Naranja y clavo',
    precio: 132,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Mojito habana club',
    descripcion: 'Ron habana club, hierbabuena, limón y azúcar',
    precio: 99,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Algonquin',
    descripcion: 'Wisky, cumo de piña y vermut.',
    precio: 109,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Americano',
    descripcion: 'Vermut rojo, Campari y soda.',
    precio: 103,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Blue lagoon',
    descripcion: 'Vodka y limonada azul frappe.',
    precio: 108,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Cosmopolitan',
    descripcion: 'Vodka, licor de naranja, jugo de arándano.',
    precio: 111,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Martini',
    descripcion: 'Ginebra y vermut seco.',
    precio: 122,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Manhattan',
    descripcion: 'Wisky, vermut, cereza.',
    precio: 125,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coctelería',
    nombre: 'Negroni',
    descripcion: 'Ginebra, campari, vermut, sumo de toronja.',
    precio: 145,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Digestivos',
    nombre: 'Carajillo',
    descripcion: 'Expresso y licor 43',
    precio: 133,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Digestivos',
    nombre: 'Godfather',
    descripcion: 'Whiskey y amaretto',
    precio: 122,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Digestivos',
    nombre: 'Beileys',
    descripcion: '',
    precio: 89,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Vinos',
    nombre: '',
    descripcion: 'Contamos con un pequeña cava, con diferentes vinos para acompañar tus alimentos.',
    precio: null,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  /*{
    categoria: 'Vinos',
    nombre: 'Copa de vino tinto',
    descripcion: '',
    precio: 88,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'clericot',
  },*/
  {
    categoria: 'Tequila',
    nombre: 'Copa de Don julio 70',
    descripcion: 'Botella $1566',
    precio: 144,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Tequila',
    nombre: 'Copa de Don Julio anejo',
    descripcion: 'Botella $1349',
    precio: 113,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Tequila',
    nombre: 'Copa de Don julio reposado',
    descripcion: 'Botella $1230',
    precio: 110,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Tequila',
    nombre: 'Copa de Don julio blanco',
    descripcion: 'Botella $1099',
    precio: 99,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Tequila',
    nombre: 'Copa de Herraduro añejo',
    descripcion: 'Botella $1349',
    precio: 133,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Tequila',
    nombre: 'Copa de Herradura reposado',
    descripcion: 'Botella $1230',
    precio: 122,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Tequila',
    nombre: 'Copa de Herradura ultra',
    descripcion: 'Botella $1455',
    precio: 145,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Tequila',
    nombre: 'Copa de Centenario añejo',
    descripcion: 'Botella $1230',
    precio: 110,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Tequila',
    nombre: 'Copa de Centenario reposado',
    descripcion: 'Botella $1062',
    precio: 94,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Tequila',
    nombre: 'Copa de Centenario plata',
    descripcion: 'Botella $849',
    precio: 87,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Tequila',
    nombre: 'Copa de 7 Leguas Blanco',
    descripcion: 'Botella $1064',
    precio: 99,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Tequila',
    nombre: 'Copa de Maestro Tequilero',
    descripcion: 'Botella $1230',
    precio: 133,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Vodka',
    nombre: 'Copa de Absolut',
    descripcion: 'Botella $1099',
    precio: 99,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Vodka',
    nombre: 'Copa de Smirnoff',
    descripcion: 'Botella $783',
    precio: 82 ,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Vodka',
    nombre: 'Copa de Stolishnaya',
    descripcion: 'Botella $895',
    precio: 92 ,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Wisky',
    nombre: 'Copa de Red label',
    descripcion: 'Botella $1000',
    precio: 99,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Wisky',
    nombre: 'Copa de Black label',
    descripcion: 'Botella $1399',
    precio: 144,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Wisky',
    nombre: 'Copa de Jack Daniels',
    descripcion: 'Botella $1062',
    precio: 99,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Wisky',
    nombre: 'Copa de Jack Daniels Honey',
    descripcion: '',
    precio: 99,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Wisky',
    nombre: 'Copa de Buchanan´s',
    descripcion: 'Botella $1566',
    precio: 145,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Ginebra',
    nombre: 'Copa de Tanqueray',
    descripcion: 'Botella $849',
    precio: 87,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Ginebra',
    nombre: 'Copa de Moms',
    descripcion: 'Botella $1175',
    precio: 104,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Ginebra',
    nombre: 'Copa de Hendricks',
    descripcion: 'Botella $1286',
    precio: 110,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Ginebra',
    nombre: 'Copa de Bombay',
    descripcion: 'Botella $1174',
    precio: 105,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Coñac',
    nombre: 'Copa de Martel VSOP',
    descripcion: '',
    precio: 145,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Brandy',
    nombre: 'Copa de Torres 10',
    descripcion: '',
    precio: 99,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Ron',
    nombre: ' Copa de Bacardi blanco',
    descripcion: 'Botella $783',
    precio: 82,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Ron',
    nombre: 'Copa de Capitan Morgan',
    descripcion: 'Botella $614',
    precio: 72,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
  {
    categoria: 'Ron',
    nombre: 'Copa de Habana Club',
    descripcion: '',
    precio: 82,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'carta_bebidas',
  },
];
