
export const categorias_bebidas = [
  {
    tipo: 'carta_bebidas',
    nombre: 'Refrescos',
    img: null,
  },
  {
    tipo: 'carta_bebidas',
    nombre: 'Sin Alcohol',
    img: null,
  },
  {
    tipo: 'carta_bebidas',
    nombre: 'Cerveza',
    img: null,
  },
  {
    tipo: 'carta_bebidas',
    nombre: 'Jarras',
    img: null,
  },
  {
    tipo: 'carta_bebidas',
    nombre: 'Café',
    img: null,
  },
  {
    tipo: 'carta_bebidas',
    nombre: 'Coctelería',
    img: null,
  },
  {
    tipo: 'carta_bebidas',
    nombre: 'Digestivos',
    img: null,
  },
  {
    tipo: 'carta_bebidas',
    nombre: 'Vinos',
    img: null,
  },
  {
    tipo: 'carta_bebidas',
    nombre: 'Tequila',
    img: null,
  },
  {
    tipo: 'carta_bebidas',
    nombre: 'Vodka',
    img: null,
  },
  {
    tipo: 'carta_bebidas',
    nombre: 'Wisky',
    img: null,
  },
  {
    tipo: 'carta_bebidas',
    nombre: 'Ginebra',
    img: null,
  },
  {
    tipo: 'carta_bebidas',
    nombre: 'Brandy',
    img: null,
  },
  {
    tipo: 'carta_bebidas',
    nombre: 'Ron',
    img: null,
  },
];
