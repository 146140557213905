export const litros_bambinos = [
  {
    categoria: 'Gin Tonic',
    nombre: 'GIN TONIC',
    descripcion: '',
    precio: 149,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Gin Tonic',
    nombre: 'PepiGin',
    descripcion: 'Pepino, Romero y twist de limón',
    precio: 149,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Gin Tonic',
    nombre: 'Frutos Rojos',
    descripcion: 'Fresa, zarzamora, blueberry y frambuesa',
    precio: 149,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Gin Tonic',
    nombre: 'BotanicGin',
    descripcion: 'Naranja y Canela',
    precio: 149,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Gin Tonic',
    nombre: 'Original',
    descripcion: 'Twist de limón',
    precio: 149,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Gin Tonic',
    nombre: 'FreshGin',
    descripcion: 'Sandía y hierbabuena',
    precio: 149,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Gin Tonic',
    nombre: 'Maracuya - Naranja',
    descripcion: '',
    precio: 149,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Mojitos',
    nombre: 'Original',
    descripcion: '',
    precio: 129,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Mojitos',
    nombre: 'Pepino',
    descripcion: '',
    precio: 129,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Mojitos',
    nombre: 'Piña',
    descripcion: '',
    precio: 129,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Mojitos',
    nombre: 'Sandia',
    descripcion: '',
    precio: 129,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Mojitos',
    nombre: 'Frutos rojos',
    descripcion: '',
    precio: 129,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Mojitos',
    nombre: 'Mango',
    descripcion: '',
    precio: 129,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Mojitos',
    nombre: 'Maracuya',
    descripcion: '',
    precio: 129,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Margaritas',
    nombre: 'Original',
    descripcion: '',
    precio: 129,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Margaritas',
    nombre: 'Frutos rojos',
    descripcion: '',
    precio: 129,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Margaritas',
    nombre: 'Fresa',
    descripcion: '',
    precio: 129,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Margaritas',
    nombre: 'Mango',
    descripcion: '',
    precio: 129,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Margaritas',
    nombre: 'Maracuya',
    descripcion: '',
    precio: 129,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  // Pendiente Mezcales: Adrian entrega precios
  {
    categoria: 'Mezcal',
    nombre: 'Malverde',
    descripcion: 'Un mojito pero con mezcal',
    precio: 159,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Mezcal',
    nombre: 'Canario',
    descripcion: 'Piña y sal de gusano',
    precio: 159,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Mezcal',
    nombre: 'Cardenal',
    descripcion: 'Guayaba y vino tinto',
    precio: 159,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Mezcal',
    nombre: 'Pinzón',
    descripcion: 'Tamarindo y chile verde',
    precio: 159,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Clásicos',
    nombre: 'Azulito',
    descripcion: 'Vodka boost',
    precio: 139,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Clásicos',
    nombre: 'Alberca ',
    descripcion: 'Vodka, ron y boost',
    precio: 139,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Clásicos',
    nombre: 'Smirnoff Tamarindo',
    descripcion: 'Vodka, sprite',
    precio: 139,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Clásicos',
    nombre: ' Paloma',
    descripcion: 'Tequila, limón, sal y squirt',
    precio: 139,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Clásicos',
    nombre: 'Bloody Mery',
    descripcion: 'Vodka, jugo tomate, limón, Tabasco, sal y pimienta',
    precio: 139,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Clásicos',
    nombre: 'Bull de cerveza',
    descripcion: 'Limonada, ron y cerveza obscura',
    precio: 139,
    tiempo_preracion: null,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Clásicos',
    nombre: 'Piña colada',
    descripcion: '',
    tiempo_preracion: null,
    precio: 139,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Clásicos',
    nombre: 'Ruso blanco',
    descripcion: 'Vodka, licor de café y leche condensada',
    tiempo_preracion: null,
    precio: 139,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
  {
    categoria: 'Clásicos',
    nombre: 'Bacardi horchata',
    descripcion: '',
    tiempo_preracion: null,
    precio: 139,
    mas_vendido: false,
    tipo: 'litros_bambinos',
  },
];
